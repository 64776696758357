(function(wnd){
    function NewsletterForm(form, params) {
        try {
            if (!form || (form && form.length == 0)) {
                throw 'Form is required!';
            } else if (form.length > 1) {
                throw 'Form must be unique element!';
            } else if (!form.is('form')) {
                throw 'Form must be a form element!';
            }

            this.form = form;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    NewsletterForm.prototype = {
        form: undefined,
        button:undefined,
        notyf: undefined,
        sendedForm: false,
        selectors: {
            button: '[data-purpose="send-form"]',
        },
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.button = this.form.find(this.getSelector('button'));

            this.notyf = new Notyf({
                delay: 4000,
            });
        },
        addDOMEvents: function() {
            var _self = this;
            this.button.click(function(){
                _self.sendForm();
            });

            this.form.on('submit', function(e){
                e.preventDefault();
                _self.sendForm();
            });
        },
        setParams: function(params) {

        },
        sendForm: function() {
            if (!this.sendedForm) {
                var _self = this;
                this.sendedForm = true;

                $.ajax({
                    url:ajax_controller+'newsletter_subscribe',
                    dataType:'json',
                    type:'post',
                    data: this.form.serialize(),
                    beforeSend: function() {
                        _self.form.find('.has-error').removeClass('has-error');
                    },
                    success: function(response) {
                        if (response.success) {
                            _self.notyf.confirm(response.msg);
                            _self.resetForm();
                        } else {
                            if (typeof response.errors !== 'undefined') {
                                $.each(response.errors, function(name, text){
                                    if (text && text.trim() !== '') {
                                        _self.notyf.alert(text);
                                    }

                                    var field = _self.form.find('[name="'+name+'"]');
                                    if (field.length > 0) {
                                        if (field.is('select')) {
                                            field.parent().addClass('has-error');
                                        } else {
                                            field.addClass('has-error');
                                        }
                                    }
                                });
                            }
                        }
                    },
                    complete: function() {
                        setTimeout(function() {
                            _self.sendedForm = false;
                        }, 2000);
                    }
                });
            }
        },
        resetForm: function() {
            this.form.find('input[type]').val('');
            this.form.find('input[type="checkbox"]').prop('checked', false);
        },
        getSelector: function(key) {
            if (typeof this.selectors[key] === 'undefined') {
                throw key+' selector is undefined!';
            }

            return this.selectors[key];
        }
    }

    wnd.NewsletterForm = NewsletterForm;
})(window);
