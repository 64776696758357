(function (wnd) {
    function CakeFormItem(key, params, parent) {
        try {
            this.key = key;

            if (parent) {
                this.parent = parent;
            }
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (e) {
            console.error(e);
        }
    }

    CakeFormItem.prototype = {
        key: undefined,
        parent: undefined,
        required: false,
        two_step_check: false,
        lead: undefined,
        lead_info: undefined,
        values: undefined,
        title: undefined,
        subtitle: undefined,
        input_name: undefined,
        input_type: 'radio',
        content: undefined,
        secondary_content: undefined,
        swipe_content: undefined,
        step: undefined,
        available: false,
        defaultHeight: 0,
        duringInit: true,
        currentActiveSwipe: undefined,
        depends_on: false,
        active: true,
        init: function () {
            this.setHtml();
            this.draw();

            // this.setRowWidth();
            this.addDOMEvents();

            this.setRowWidth();

            this.duringInit = false;
        },
        addDOMEvents: function () {
            var _self = this;
                        
            if (this.depends_on) {
                var triggerName = 'makvirag.change_'+this.depends_on;
                
                $(document).on(triggerName, function(e, data){
                    _self.refreshContent(data);
                });
            }

            var resizeFn = debounce(function () {
                _self.setRowWidth();
            }, 100);

            window.addEventListener('resize', resizeFn);

            this.addInputDOMEvents();
        },
        addInputDOMEvents: function() {
            var _self = this;

            this.content.find('input').change(function(){
                var triggerData = {
                    input: $(this),
                    dependValue: typeof _self.values[$(this).val()] !== 'undefined' ? _self.values[$(this).val()] : false
                };
                var triggerName = 'makvirag.change_'+_self.input_name;
                $(document).trigger(triggerName, triggerData);   
                if (!_self.isTwoStepCheck() || (_self.isTwoStepCheck() &&  !_self.secondary_content)) {
                    _self.hideSwipeContent(400, _self.currentActiveSwipe);
                    _self.parent.openNextSteps(_self.step);
                } 
            });

            this.content.find('> .inner').on('scroll', function () {
                if (_self.currentActiveSwipe) {
                    _self.hideSwipeContent(0, _self.currentActiveSwipe);
                }
            });

            this.content.find('.makvirag-form-row').on('scroll', function() {
                if (_self.currentActiveSwipe) {
                    _self.hideSwipeContent(0, _self.currentActiveSwipe);
                }
            });

            this.swipe_content.on('touchstart', function () {
                if (_self.currentActiveSwipe) {
                    _self.hideSwipeContent(0, _self.currentActiveSwipe);
                }
            });

        },
        openMainContent: function () {
            if (this.content.hasClass('hidden-content') ||  !this.isAvailable()) {
                this.setAvailability(true);
                this.content.removeClass('hidden-content');
                this.defaultHeight = this.content.height();
            }
        },
        
        setMinHeight: function () {
            if (this.secondary_content && this.isTwoStepCheck()) {
                this.content.removeAttr('style');

                var height = 0;
                this.secondary_content.find('.second-step').each(function () {
                    var itemHeight = $(this).outerHeight();
                    if (itemHeight > height) {
                        height = itemHeight;
                    }
                });

                height += this.secondary_content.outerHeight();
                height += this.secondary_content.find('.head-content').outerHeight();

                this.content.css('min-height', height);
            }
        },
        setRowWidth: function () {
            var _self = this;
            var items = null;
            this.content.find('.makvirag-form-row').removeAttr('style');
            if ($(window).width() > 600) {
                return;
            }

            switch (this.key) {
                case 'cake-size':
                case 'cake-type':
                    items = this.content.find('li');
                    break;
                default:
                    items = this.content.find('.main-row .button-radio');
                    break;
            }
            
            if (items !== null && items.length > 0) {
                var width = 0;
                $.each(items, function (index, item) {
                    if ($(item).is(':visible')) {
                        width += Math.ceil($(item).outerWidth());

                        if (_self.key == 'cake-size') {
                            width += parseInt($(item).css('margin-right'));
                        }

                        if (_self.getInputType() == 'checkbox') {
                            width += parseInt($(item).css('margin-left'));
                        }
                    }
                });
                

                var row = this.content.find('.makvirag-form-row.main-row');
                if (row.length > 0) {
                    this.content.removeClass('single-content');

                    if (width <= $(window).width()) {
                        this.content.addClass('single-content');
                    } 

                    if (width > $(window).width() && $(window).width() <= 600 &&  (!this.duringInit || this.step == 1) && _self.key != 'letter-type') {
                        this.showSwipeIcon('main');
                    }
                }
            }


        },
        
        setParams: function (params) {
            if (params.title) this.setTitle(params.title);
            if (params.subtitle) this.setSubtitle(params.subtitle);
            if (params.required) this.setRequired(params.required);
            if (params.values) this.setValues(params.values);
            if (params.key) this.setKey(params.key);
            if (params.input_name) this.setInputName(params.input_name);
            if (params.input_type) this.setInputType(params.input_type);
            if (params.two_step_check) this.setTwoStepCheck(params.two_step_check);
            if (params.step) this.setStep(params.step);
            if (params.available) this.setAvailability(params.available);
            if (params.lead) this.setLead(params.lead);
            if (params.lead_info) this.setLeadInfo(params.lead_info);
            if (params.depends_on) this.setDependsOn(params.depends_on);
        },
        draw: function () {
            this.parent.container.append(this.content);

            this.defaultHeight = this.content.height();
        },
        setHtml: function (option) {
            this.content = $('<div>', {
                'class': 'form-block ' + this.key + ' ' + (this.isAvailable() ? '' : 'hidden-content') + (this.step == this.parent.getCurrentStep() ? 'current-step' : ''),
                'data-key': this.key,
                'data-step': this.step
            });

            var lead = this.getLead();

            var title = $('<h2>', {
                html: this.getTitle(),
                class: 'primary-title ' + (lead !== null ? 'has-lead' : ''),
                
            });

            this.content.append(title);
            
           
            var inner = $('<div>', {
                class: 'inner'
            });

            inner.append(this.createFormInnerHtml(option));
            this.swipe_content = $('<span>', {
                'class': 'swipe-outer',
                'html': svg('swipe-icon')
            });

            inner.append(this.swipe_content);
            this.content.append(inner);

            if (this.depends_on) {
                this.content.addClass('no-value');
            }

            
            this.addOptionLead(option);
            this.addLeadInfo(option);
        },
        createFormInnerHtml: function (option) {
            switch (this.key) {
                case 'cake-type':
                    return this.createCakeTypeContent(option);
                default:
                    return this.createDefaultFormContent(option);
            }
        },
        createCakeTypeContent: function(option) {
            var container = $('<div>', {
                'class': 'makvirag-form-row main-row'
            });
            
            var valueKeys = Object.keys(this.values);
            if (valueKeys.length > 0) {
                var list = $('<ul>');

                for (var i = 0; i < valueKeys.length; i++) {
                    var key = valueKeys[i];
                    var value = this.values[key];
                    var id = this.getKey() + '-' + key;

                    var item = $('<li>');
                    

                    var input = $('<input>', {
                        type:'radio',
                        name:this.getInputName(),
                        id:id,
                        value:key,
                        class:'button-radio'
                    });

                    var label = $('<label>', {
                        for:id,
                        html:'<span class="image-outer"><img src="'+value.image+'" alt="'+this.getText(value.label)+'" class="'+(key.replace('_', '-'))+'" /></span><span class="name"><span class="text">'+this.getText(value.label)+'</span></span>'
                    });

                    item.append(input);
                    item.append(label);

                    list.append(item);
                }
                
                container.append(list);
            }

            return container;
        },
        createDefaultFormContent: function (option) {
            var container = $('<div>', {
                'class': 'makvirag-form-row main-row'
            });

            var values = option ? this.values[option] : this.values;
            var options = option ? values.options : values;
            var valueKeys = Object.keys(options);
            if (valueKeys.length > 0) {
                var list = $('<ul>');

                for (var i = 0; i < valueKeys.length; i++) {
                    var item = $('<li>');
                    var key = valueKeys[i];
                    var value = options[key];
                    var id = this.getKey() + '-' + key;
                    var nameText = typeof value.label !== 'undefined' ? this.getText(value.label) : typeof value.name !== 'undefined' ? value.name : '';
                    if (!nameText || (nameText && nameText.trim() == '')) {
                        continue;
                    }
                    
                    var input = $('<input>', {
                        type: this.getInputType(),
                        id: id,
                        name: this.getInputName(true) + (this.getKey() == 'letter-type' ? 'parent' : ''),
                        value: key,
                        'data-level': 'main',
                    });
                    item.append(input);

                    var label = $('<label>', {
                        for: id,
                        class: this.getKey() + '-radio',
                        'data-key': key
                    });

                    if (typeof value.icon !== 'undefined') {
                        label.append('<span class="icon-content ' + this.getKey() + ' ' + (this.getKey() == 'letter-painting' ? key : '') + '">' + svg(value.icon) + '</span>');
                    }

                    if (typeof value.name !== 'undefined' || typeof value.label !== 'undefined') {
                        var name = typeof value.label !== 'undefined' ? this.getText(value.label) : value.name;

                        if (typeof value.image !== 'undefined' && value.image != '') {
                            label.append('<span class="image-content ' + this.getKey() + '"><img src="' + value.image + '" alt="' + name + '" /></span>');
                        }

                        label.append('<span class="name">' + name + '</span>');
                    }

                    if (typeof value.price !== 'undefined') {
                        label.append('<span class="price">'+value.price+'</span>');
                    }


                    item.append(label);

                    list.append(item);
                }

                container.append(list);
                
            }

            return container;
        },
        addOptionLead: function(option) {
            var values = option ? this.values[option] : this.values;
            var lead = undefined;
            if (this.key == 'extensions') {
                lead = this.lead;
            }
            
            if (typeof values.lead !== 'undefined' || lead) {
                if (!lead) {
                    lead = values.lead;
                }
                var txt = this.getText(lead);
                
                if (txt && txt !== lead) {
                    this.content.find('.inner').append('<div class="form-content-lead">'+txt+'</div>');
                }
            }
        },
        refreshContent: function(data) {
            var inner = this.content.find('.inner');
            inner.html('');
            this.content.find('.lead-info').remove();

            this.swipe_content = $('<span>', {
                'class': 'swipe-outer',
                'html': svg('swipe-icon')
            });

            inner.append(this.swipe_content);
            
            var option = $(data.input).val();
            if (typeof this.values[option] !== 'undefined') {
                this.content.removeClass('no-value');
                
                if (typeof data.dependValue !== 'undefined') {
                    var type = typeof data.dependValue.label !== 'undefined' ? data.dependValue.label : '';
                    var title = this.getTitle();
                    if (title) {
                        title = title.replace('%TYPE%', this.getText(type));
                        this.content.find('.primary-title').html(title);
                    }
                }

                inner.append(this.createFormInnerHtml(option));
                this.addOptionLead(option);
                this.addLeadInfo(option);
                this.addInputDOMEvents();
            } else {
                this.content.addClass('no-value');
            }

            this.setRowWidth();
            this.parent.refreshNavigation();
        },
        setKey: function (key) {
            this.key = key;
        },
        setInputName: function (input_name) {
            this.input_name = input_name;
        },
        setInputType: function (input_type) {
            this.input_type = input_type;
        },
        setTitle: function (title) {
            this.title = title;
        },
        setSubtitle: function (subtitle) {
            this.subtitle = subtitle;
        },
        setRequired: function(required) {
            this.required = required;
        },
        setTwoStepCheck: function (two_step) {
            this.two_step_check = two_step;
        },
        setValues: function (values) {
            this.values = values;
        },
        setStep: function (step) {
            this.step = step;
        },
        setAvailability: function (available) {
            this.available = available;
        },
        setLead: function (lead) {
            this.lead = lead;
        },
        setDependsOn: function(depends_on) {
            this.depends_on = depends_on;
        },
        getTitle: function () {
            return this.title ? this.getText(this.title) : null;
        },
        getLead: function () {
            return this.lead ? this.getText(this.lead) : null;
        },
        addLeadInfo: function(option) {
            var values = option && typeof this.values[option] !== 'undefined' ? this.values[option] : this.values; 
            if (values && typeof values.lead_info !== 'undefined') {
                var info = this.getText(values.lead_info);

                if (info) {
                    var inner = this.content.find('.inner');
                    var lead = $('<div>', {
                        'class':'lead-info',
                        'html': info
                    });

                    lead.insertBefore(inner);
                }
            }
        },
        getSubtitle: function (name) {
            if (name) {
                var subtitle = this.subtitle;
                if (subtitle) {
                    subtitle = subtitle.replace('%NAME+', this.getText(key));
                    return subtitle;
                }
            }

            return this.subtitle ? this.getText(this.subtitle) : null;
        },
        getKey: function () {
            return this.key;
        },
        getInputName: function (parent) {
            var name = this.input_name ? this.input_name : this.getKey();

            var inputName = 'offer_data[' + name + (parent && this.isTwoStepCheck() ? '_parent' : '') + ']';

            return inputName + (this.getInputType() === 'checkbox' ? '[]' : '');
        },
        getInputType: function () {
            return this.input_type;
        },
        getText: function (key) {
            return this.parent ? this.parent.getText(key) : key;
        },
        isTwoStepCheck: function () {
            return this.two_step_check;
        },
        isRequired: function () {
            return this.required;
        },
        isAvailable: function () {
            return this.available;
        },
        isMobileView: function () {
            return $(window).width() <= 600;
        },
        showSwipeIcon: function (type) {
            var _self = this;
            if (!this.swipe_content.hasClass(type)) {
                this.swipe_content.addClass(type);
                this.swipe_content.show();
                this.currentActiveSwipe = type;

                setTimeout(function () {
                    if (!_self.swipe_content.hasClass('closed-' + type)) {
                        _self.hideSwipeContent(400, type);
                    }
                }, _self.parent.getHideTime());
            }
        },
        hideSwipeContent: function (time, type) {
            if (this.isMobileView()) {
                if (time == undefined) {
                    time = 400;
                }

                this.swipe_content.fadeOut(time);
                this.swipe_content.addClass('closed-' + type);
            }
        },
        isAvailableContent: function() {
            return !this.content.hasClass('no-value');
        }
    }

    function CakeForm(container, params) {
        try {
            if (!container) {
                throw 'Container';
            }

            this.container = container;

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (e) {
            console.error(e);
        }
    }


    CakeForm.prototype = {
        container: undefined,
        formItemObjs: undefined,
        formItems: undefined,
        formContent: undefined,
        sendedForm: false,
        nextButton: undefined,
        prevButton: undefined,
        navigationRow: undefined,
        texts: {},
        currentStep: 1,
        availableStep: 1,
        dateField: undefined,
        maxSteps: 6,
        inputFields: ['name', 'phone', 'email'],
        hideTime: 9000,
        notyf: undefined,
        usedNavigationRow: false,
        options: {
            minDay: 2,
        },
        init: function () {
            this.setFormObjs();
            this.notyf = new Notyf({
                delay: 4000
            });

            this.createFormContent();
            
            this.createNavigationRow();
            this.addDOMEvents();

            if (this.availableStep == this.maxSteps) {
                this.formContent.removeClass('hidden-content');
            }
        },
        addDOMEvents: function () {
            var _self = this;
            
            this.container.find('[data-purpose="form-send"]').click(function () {
                _self.sendForm();
            });

            this.formContent.find('.phone-field').keypress(function (e) {
                var code = typeof e.which !== 'undefined' && e.which ? e.which : e.keyCode;
                var exeptions = [37, 39];
                var key = typeof e.key !== 'undefined' ? e.key : undefined;

                if (key && (key == "'" ||  key == "%")) {
                    return false;
                }

                if (exeptions.indexOf(code) >= 0) {
                    return true;
                }

                if (code > 31 && (code < 48 || code > 57)) {
                    return false;
                }

                return true;
            });

            if (this.availableStep <= this.currentStep) {
                this.nextButton.hide();
            }

            if (this.currentStep == 1) {
                this.prevButton.hide();
            }
           
            
            if (!this.isMobileBrowser()) {
                switch (frontend_lang) {
                    case 'hu':
                        $.datepicker.setDefaults($.datepicker.regional['hu']);
                        break;
                }

                
                
                this.dateField.attr('readonly', true);
                var days = this.getOption('minDay');


                this.dateField.datepicker({
                    appendTo: _self.dateField.parent(),
                    //minDate:'+'+days+'days',
                    minDate:0,
                    beforeShow: function(input, inst) {                        
                        inst.dpDiv.addClass('opened-datepicker');
                    },
                    onClose: function(input, inst) {
                        inst.dpDiv.removeClass('opened-datepicker');
                    }, 
                    onSelect: function(input, inst) {
                        
                        var dateStr1 = _self.createDate(0);

                        var dateStr2 = _self.createDate(days);
                        
                        var tmp = input.slice(0,-1);

                        tmp = tmp.replace(/\./g, "-");

                        var from = new Date(dateStr1);
                        var to   = new Date(dateStr2);
                        var check = new Date(tmp);

                        if (from<=check && to>check) {
                            var remodal = $('[data-remodal-id="cakeRemodal"]');
                            if (remodal.length > 0) {
                                remodal.remodal().open();
                            }
                        }

                        if (input == inst.lastVal) {
                            inst.input.val('');
                            inst.lastVal = '';
                        }
                    }
                });
            } else {
                var date = new Date();
                date.setDate(date.getDate() + this.getOption('minDay'));
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var day = date.getDate();
                if (month < 10) {
                    month = '0'+month;
                }
    
                if (day < 10) {
                    day = '0'+day;
                }
                var dateStr = year+'-'+month+'-'+day;
                this.dateField.attr('min', dateStr);
                
                this.dateField.change(function(){
                    var value = $(this).val();
                    if (!value && $(this).hasClass('has-value')) {
                        $(this).removeClass('has-value');
                    } else if (value && !$(this).hasClass('has-value')) {
                        $(this).addClass('has-value');
                    }
                });

            }

            this.addNavigationDOMEvents();
        },
        createDate: function(days){
            var date = new Date();
            date.setDate(date.getDate() + days);
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var day = date.getDate();
            if (month < 10) {
                month = '0'+month;
            }

            if (day < 10) {
                day = '0'+day;
            }
            return year+'-'+month+'-'+day;
        },
        addNavigationDOMEvents: function() {
            var _self = this;

            this.prevButton.click(function () {
                if (_self.currentStep > 1) {
                    _self.goToPage(-1);
                }
            });

            this.nextButton.click(function () {
                var step = _self.formItemObjs[_self.currentStep];

                if (((!step.isRequired() && step.isAvailableContent()) || _self.currentStep < _self.availableStep) && _self.currentStep < _self.maxSteps) {
                    _self.goToPage(1);
                }
            });
        },
        setParams: function (params) {
            if (params.formItems) this.setFormItems(params.formItems);
            if (params.texts) this.setTexts(params.texts);
            if (params.availableStep) this.setAvailableStep(params.availableStep);
            if (params.maxSteps) this.setMaxSteps(params.maxSteps);
            if (params.inputFields) this.setInputFields(params.inputFields);
            if (params.options) this.setOptions(params.options);
        },
        setOptions: function(options) {
            this.options = $.extend({}, this.options, options);
        },
        getOption: function(key) {
            if (typeof this.options[key] === 'undefined') {
                throw key+' option not exists!';
            }

            return this.options[key];
        },
        setFormItems: function (items) {
            this.formItems = items;
        },
        setFormObjs: function () {
            if (this.formItems) {
                var keys = Object.keys(this.formItems);
                if (keys.length > 0) {
                    this.formItemObjs = {};
                    var step = 1;
                    for (var i = 0; i < keys.length; i++) {
                        this.formItems[keys[i]].step = step;
                        this.formItems[keys[i]].available = parseInt(step) <= this.getAvailableStep();
                        this.formItemObjs[step] = new CakeFormItem(keys[i], this.formItems[keys[i]], this);
                        step++;
                    }
                }
            }
        },
        createFormContent: function () {
            this.formContent = $('<div>', {
                class: 'form-block contact-section hidden-content',
                'data-key': 'form',
                'data-step': this.maxSteps
            });

            var leftContent = $('<div>', {
                class: 'input-content'
            });

            var row = $('<div>', {
                class:'form-row'
            });

            var isMobile = this.isMobileBrowser();
            var dateOptions = {
                type:isMobile ? 'date' : 'text',
                name:'date',
                class:'field date-field',
                placeholder: this.getText('date_placeholder')
            };

            if (isMobile) {
                var date = new Date();
                date.setDate(date.getDate() + 1);
                var month = date.getMonth() + 1;
                if (month < 10) {
                    month = '0'+month;
                }

                var day = date.getDate();
                if (day < 10) {
                    day = '0'+day;
                }
                
                dateOptions.min = date.getFullYear() + '-' + month + '-' + day;
            } else {
                dateOptions.readonly = true;
            }

            this.dateField = $('<input>', dateOptions);

            var dateOuter = $('<div>', {
                class:'input-outer',
                id:'dateFieldOuter'
            });

            dateOuter.append(this.dateField)

            row.append(dateOuter);
            row.append('<div class="top-info">'+this.getText('contact_form_info_text')+'</div>');

            leftContent.append(row);

            var bottomContent = $('<div>', {
                'class':'bottom-content'
            });

            var bottomLeft = $('<div>', {
                'class':'left-content'
            });

            if (this.inputFields.length > 0) {
                for (var i = 0; i < this.inputFields.length; i++) {
                    var field = this.inputFields[i];

                    var fieldRow = $('<div>', {
                        class:'form-row'
                    });

                    
                    fieldRow.append($('<input>', {
                        class:'field',
                        type:'text',
                        placeholder: this.getText(field + '_placeholder'),
                        id: 'form-' + field,
                        'data-required': true,
                        name:field
                    }));

                    bottomLeft.append(fieldRow);
                }
            }

            bottomContent.append(bottomLeft);

            bottomContent.append($('<div>', {
                'class':'textarea-outer',
                'html': $('<textarea>', {
                    'name':'message',
                    'class':'field textarea-field',
                    'placeholder':this.getText('message_placeholder'),
                    'id':'form-message'
                })
            }));

            leftContent.append(bottomContent);

            var chkFields = ['privacy', 'newsletter'];
            var checkboxesOuter = $('<div>', {
                class:'checkbox-outer'
            });

            for (var i = 0; i < chkFields.length; i++) {
                var row = $('<div>', {
                    class:'checkbox-row'
                });

                row.append('<input name="'+chkFields[i]+'" type="checkbox" value="1" id="cake-'+chkFields[i]+'" />');
                row.append('<label for="cake-'+chkFields[i]+'">'+this.getText(chkFields[i]+'_checkbox_label')+'</label>');

                checkboxesOuter.append(row);
            }

            leftContent.append(checkboxesOuter);

            var inner = $('<div>', {
                class: 'inner-content'
            });

            inner.append(leftContent);
            inner.append(this.createFormInfoContent());

            var buttonRow = $('<div>', {
                'class':'button-row',
                'html':  $('<button>', {
                    class: 'button',
                    type: 'button',
                    'data-purpose': "form-send",
                    'html': this.getText('form_button_text')
                })
            });

            inner.append(buttonRow);

            this.formContent.append(inner);
            this.container.append(this.formContent);
        },
        createFormInfoContent: function () {
            var rightContent = $('<div>', {
                class: 'info-content',
                html: this.getText('form_info_box_text')
            });

            return rightContent;
        },
        createNavigationRow: function ()  {
            this.navigationRow = $('<div>', {
                'class': 'navigation-row '+(!this.usedNavigationRow ? 'inactive' : ''),
            });

            this.prevButton = $('<a>', {
                'href': 'javascript:void(0)',
                'class': 'navigation-button prev-button',
                'data-purpose': 'navigation-button',
                'data-direction': 'prev',
                'html': '<span class="inner">'+svg('arrow-right')+'<span class="text">'+this.getText('prev_step')+'</span></span>',
            });

            this.nextButton = $('<a>', {
                'href': 'javascript:void(0)',
                'class': 'navigation-button next-button',
                'data-purpose': 'navigation-button',
                'data-direction': 'next',
                'html': '<span class="inner"><span class="text">'+this.getText('next_step')+'</span>'+svg('arrow-right')+'</span>',
            });

            var maxSteps = this.maxSteps;
            $.each(this.formItemObjs, function(index, obj){
                if (!obj.isAvailableContent()) {
                    maxSteps--;
                }
                
                if (obj.getKey() == 'form-break-options' && obj.isAvailableContent()) {
                    maxSteps--;
                    maxSteps--;
                }
            });

            var counterContent = $('<span>', {
                'class': 'counter-content',
                'html': '<span data-purpose="current-step">' + this.getCurrentStep() + '</span>/' + maxSteps
            });          

            this.navigationRow.append(this.prevButton);
            this.navigationRow.append(this.nextButton);
            this.navigationRow.append(counterContent);
            this.container.append(this.navigationRow);

            if (this.getCurrentStep() == 1) {
                this.prevButton.hide();
            }
        },
        refreshNavigation: function() {
            this.navigationRow.remove();
            this.createNavigationRow();

            if (!this.usedNavigationRow) {
                var _self = this;

                setTimeout(function() {
                    _self.navigationRow.removeClass('inactive');
                }, 400);
            }
            this.addNavigationDOMEvents();
        },
        openNextSteps: function (step) {
            var nextStep = parseInt(step) + 1;
            if (this.getAvailableStep() - parseInt(step) == 0) {
                var skip = false;
                if (typeof this.formItemObjs[nextStep] !== 'undefined') {
                    if (!this.formItemObjs[nextStep].isAvailableContent()) {
                        this.setAvailableStep(nextStep);
                        this.openNextSteps(nextStep);
                        skip = true;
                    }
                }
                
                if (!skip) {
                    if (typeof this.formItemObjs[nextStep] !== 'undefined' &&  nextStep < this.maxSteps) {
                        this.setAvailableStep(nextStep);
                        this.formItemObjs[nextStep].openMainContent();
                        this.nextButton.fadeIn(300);
                        if (!this.formItemObjs[nextStep].isRequired()) {
                            this.openNextSteps(nextStep);
                        }
                    } else if (nextStep == this.maxSteps) {
                        this.formContent.removeClass('hidden-content');
                    }
                }
            } else {
                if (typeof this.formItemObjs[nextStep] !== 'undefined' && step == 1) {
                    var input = this.formItemObjs[nextStep].content.find('input').is(':checked');
                    if (!input) {
                        this.setAvailableStep(step);
                        this.hideFurtherSteps(step);
                        this.openNextSteps(step);
                    }
                }
            }
        },
        hideFurtherSteps: function (step) {
            this.setAvailableStep(step);
            for (var i = step + 1; i <= this.maxSteps; i++) {
                if (i == this.maxSteps) {
                    this.formContent.addClass('hidden-content');
                } else {
                    this.formItemObjs[i].setAvailability(false);
                    this.formItemObjs[i].content.addClass('hidden-content');
                }
            }

            this.nextButton.fadeOut(300);
        },
        goToPage: function (direction) {
            var nextStep = this.currentStep + direction;
            $(window).scrollTop(0);
            if (nextStep > 1) {
                this.prevButton.fadeIn(300);
            } else {
                this.prevButton.fadeOut(300);
            }

            var valid = true;

            if (this.currentStep == this.maxSteps) {
                this.formContent.removeClass('current-step');
                this.formItemObjs[nextStep].content.addClass('current-step');
                this.nextButton.fadeIn(300);
            } else {
                var current = typeof this.formItemObjs[this.currentStep] !== 'undefined' ? this.formItemObjs[this.currentStep] : this.formContent;
                var next = typeof this.formItemObjs[nextStep] !== 'undefined' ? this.formItemObjs[nextStep] : this.formContent;
                
                if ((typeof next.isAvailableContent === 'function' && next.isAvailableContent()) || typeof next.isAvailableContent === 'undefined') {
                    current.content.removeClass('current-step');

                    if (nextStep < this.maxSteps && next.isRequired() &&  this.availableStep <= nextStep) {
                        this.nextButton.fadeOut(300);
                    } else if (nextStep < this.maxSteps) {
                        this.nextButton.fadeIn(300);
                    }

                    if (nextStep == this.maxSteps) {
                        this.nextButton.fadeOut(300);
                        next.addClass('current-step');
                    } else {
                        next.content.addClass('current-step');
                        next.setRowWidth();
                    }
                } else {
                    valid = false;
                    this.currentStep = nextStep;
                    current.content.removeClass('current-step');
                    this.goToPage(direction);
                }
            }
            if (valid) {
                var skip = 0;
                for (var i = 1; i <= nextStep; i++) {
                    if (typeof this.formItemObjs[i] !== 'undefined') {
                        if (!this.formItemObjs[i].isAvailableContent()) {
                            skip++;
                        }
                    }
                }

                if (nextStep == 1) {
                    $('body').removeClass('remove-page-header');
                } else {
                    $('body').addClass('remove-page-header');
                }

                this.navigationRow.find('[data-purpose="current-step"]').html(nextStep - skip);
                this.currentStep = nextStep;
            }
        },
        setAvailableStep: function (step) {
            this.availableStep = parseInt(step);
        },
        setTexts: function (texts) {
            this.texts = $.extend({}, this.texts, texts);
        },
        setSelectedTombType: function (type) {
            this.selectedTombType = type;
        },
        setMaxSteps: function (steps) {
            this.maxSteps = steps;
        },
        setInputFields: function (fields) {
            this.inputFields = fields;
        },
        getText: function (key) {
            return typeof this.texts[key] !== 'undefined' ? this.texts[key] : key;
        },
        getCurrentStep: function () {
            return parseInt(this.currentStep);
        },
        getAvailableStep: function () {
            return parseInt(this.availableStep);
        },
        getSelectedTombType: function () {
            return this.selectedTombType;
        },
        sendForm: function () {
            if (!this.sendedForm) {
                var _self = this;
                this.sendedForm = true;

                $.ajax({
                    url: _self.container.attr('action'),
                    dataType: 'json',
                    type: 'post',
                    data: _self.container.serialize(),
                    beforeSend: function () {
                        _self.formContent.find('.has-error').removeClass('has-error');
                    },
                    success: function (response) {
                        if (typeof gtag !== 'undefined') {
                            var nameInput = $('#form-name');
                            if (nameInput.length == 1) {
                                var success = response.success;
                                
                                gtag('event', 'Ajánlatkérés', {
                                    'event_category': 'Lead',
                                    'event_action': (success ? 'Sikeres' : 'Sikertelen') + ' ajánlatkérés',
                                    'event_label': nameInput.val()
                                });
                            }
                        }
                        if (response.success) {
                            if (typeof response.url !== 'undefined' && response.url) {
                                document.location.href = response.url;
                            } else {
                                _self.showConfirmBlock();
                            }
                        } else {
                            if (typeof response.errors == 'object') {
                                var keys = Object.keys(response.errors);

                                if (keys.length > 0) {
                                    for (var i = 0; i < keys.length; i++) {
                                        var item = $('#form-' + keys[i]);
                                        var input = $('input[name="'+keys[i]+'"]');

                                        if (item.length > 0) {
                                            item.parents('.form-row').addClass('has-error');
                                        }

                                        if (input.length > 0) {
                                            input.addClass('has-error');
                                        }

                                        if (response.errors[keys[i]] !== '') {
                                            _self.notyf.alert(response.errors[keys[i]]);
                                        }
                                    }
                                }
                            }
                        }

                    },
                    complete: function () {
                        setTimeout(function () {
                            _self.sendedForm = false;
                        }, 1000);
                    }
                });
            }
        },
        showConfirmBlock: function () {
            this.formContent.html('');

            var content = $('<div>', {
                class: 'confirm-block'
            });

            content.append(svg('success-icon'));
            content.append('<h2>' + this.getText('success_form_title') + '</h2>');
            content.append('<div class="confirm-text">' + this.getText('success_form_text') + '</div>');

            this.formContent.append(content);
        },
        setHideTime: function (hide_time) {
            this.hideTime = hide_time;
        },
        getHideTime: function () {
            return this.hideTime;
        },
        isMobileBrowser: function() {
            var check = false;
            (function(a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
                check = true;
                }
            )(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        }
    };

    wnd.CakeForm = CakeForm;
})(window);
