window.loaded = false;
var windowWidth = 0;
var headerDragon = undefined;

$(window).on('load', function(){
	window.loaded = true;
	
	if (headerDragon && headerDragon.length > 0) {
		headerDragon.addClass('active');
	}
});

var headerBorder = undefined;
var mesekertMap = undefined;

var mapContent = undefined;

var homeSlider = undefined;

$(document).ready(function() {
	/* MOBILE */
	//create js hook links
	windowWidth = window.innerWidth;
	var resizeFn = function() {
		if (windowWidth != window.innerWidth) {
			windowWidth = window.innerWidth;
			$(document).trigger('makvirag.window_width_changed', windowWidth);

			console.log(homeSlider);

			if (typeof homeSlider !== 'undefined') {
				homeSlider.destroySlider();
				initHomeSlider(windowWidth < 600 ? 'mobile' : 'desktop');
			}
		}
	}

	window.addEventListener('resize', resizeFn);

	$('.mailtolink').click(function(e) {
		handleMailtoLink($(this));
	});

	$('[data-purpose="navigation-link"]').each(function(){
		setNavigationUrl($(this));
	});
	
	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('popup-target');
		if ($(this).data('popup-target')) {
			var item = $('[data-remodal-id="' + $(this).data('popup-target') + '"]');
			if (item.length > 0) {
				item.remodal().open();
			}
		}
	});
	
	if( $('.cookie-bar').length > 0 ) {

		$('.cookie-bar .button').click(function(){
			$('.cookie-bar').addClass('closed');

			$.ajax({
				url:ajax_controller+'setCookieConfirm',
				dataType:'json',
				type:'post',
				data:{cookie:1},
				success: function(){}
			});
		});
	}

	$('[data-purpose="remodal-close"]').click(function(){
		var value = $(this).data('value');
		if (value && value == 'confirm') {
			$('#terms-chk').attr('checked', true);
		}

		$(this).parents('.remodal').remodal().close();
	});

	$('[data-purpose="scroll-to-element"]').click(function(){
		var target = $(this).data('target');
		var item = $(target);
		var timeout = $(this).data('timeout');
		if (isNaN(timeout)) {
			timeout = 400;
		}
		
		if (item.length > 0) {
			$('html, body').animate({scrollTop: item.offset().top}, timeout);
		}
	});

	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('target');
		if (target) {
			openRemodalContent(target);
		}
	});

	$('[data-purpose="privacy-confirm-button"]').click(function(){
		var type = $(this).data('type');
		$('#privacyChk').prop('checked', type == 'accept');
		$('[data-remodal-id="privacyRemodal"]').remodal().close();
	});

	
	if (typeof site_id !== 'undefined') {
		if (site_id == 1) {
			var type = window.innerWidth < 600 ? 'mobile' : 'desktop';
			initHomeSlider(type);
		}
	}

	if (typeof articleGalleryItems !== 'undefined') {
		$('.article-gallery-header-item').click(function(){
			openArticleGallery($(this));
		});
	}

	initGalleries();

	mapContent = $('[data-purpose="mesekert-map"]');
	if (mapContent && mapContent.length > 0) {
		initMap();
	}

	$('[data-purpose="close-hello-bar"]').click(function(){
		closeHelloBar($(this));
	});
});


function openRemodalContent(remodal_id) {
	var tmp = $('[data-remodal-id="'+remodal_id+'"]');
	if(tmp.length > 0){
	  tmp.remodal().open();
	}
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function sendAnalyticsEvent(action, category, tag) {
	if (typeof gtag !== 'undefined' && action && category) {
		var evtData = {
			event_category: category,
		};

		if (tag && tag.trim() != '') {
			evtData.event_label = tag;
		}
		
		gtag('event', action, evtData);
	}
	else if(typeof ga != 'undefined' && action && category){
		if(tag && tag.trim() != ''){
			ga('send', 'event', action, category, tag);
		}else{
			ga('send', 'event', action, category);
		}
	}
}

function svg(id) {
	return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}


function handleMailtoLink(th) {
	if (th.attr('href') == 'javascript:void(0)') {
		var hidden = th.children('.emailhidden');
		th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
	}
}


function loadJs(url, implementationCode){
    var scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = implementationCode;
    scriptTag.onreadystatechange = implementationCode;

    document.body.appendChild(scriptTag);
};

function setNavigationUrl(item) {
	var address = item.data('address');
	
	if (address) {
		var protocol = 'google';
		var ua = navigator.userAgent.toLowerCase();
		var plat = navigator.platform;
		var device = ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i) ? ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i)[0] : false;

		if(device == 'iphone' || device == 'ipad' || device == 'ipod'){
			function iOSversion() {
					
				if (/iP(hone|od|ad)/.test(navigator.platform)) {
				  var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
				  return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
				}
			}

			var ver = iOSversion() || [0];
			if (ver[0] >= 6) {
				protocol = 'apple';
			}
		}
		
		var urlSuffix = '/?daddr='+address;
		var nav_url = '';
		switch(protocol) {
			case 'apple':
				nav_url = 'maps://';
				break;
			default:
				nav_url = 'https://maps.google.com/maps';
				break;
		}
		item.attr('target', '_blank');
		item.attr('href', nav_url+urlSuffix);
	}
}


function openGallery(item) {
	var groupId = item.data('group-id');
	var index = item.data('index');
	
	if (typeof galleryItems === 'object' && typeof galleryItems[groupId] !== 'undefined') {
		if (!index) {
			index = 0;
		}

		initGallery(item, galleryItems[groupId], index);
	} 
}

function openArticleGallery(item) {
	var groupId = $(item).data('gallery-id');
	if (groupId && typeof articleGalleryItems === 'object' && typeof articleGalleryItems[groupId] !== 'undefined') {
		initGallery(item, articleGalleryItems[groupId]);
	}
}

function initGallery(target, items, index) 
{
	if (!index) {
		index = 0;
	}

	$(target).lightGallery({
		dynamic:true,
		dynamicEl:items,
		index:index,
		hash:false,
		download: false
	});
}

function getMapStyle()
{
	return [];
}

function initGalleries()
{
	if (typeof mediaGroupItems === 'object' && Object.keys(mediaGroupItems).length > 0) {
		$('[data-purpose="gallery-box-item"]:not(.initialized)').each(function(){
			$(this).addClass('initialized');
			$(this).click(function(){
				var id = $(this).data('group-id');
				if (id && typeof mediaGroupItems[id] !== 'undefined') {
					initGallery($(this), mediaGroupItems[id]);
				}
			});
		});
	}
}

function initMap() {
	if (mapboxgl.supported()) {
		mapboxgl.accessToken = 'pk.eyJ1Ijoic2VydWthIiwiYSI6ImNqNmhxbjF2ejA5YXAzMnRlN3o4ZjBhdTQifQ.Wj8inzuV9QHNCovlvPucSg';
		var center = [21.67279, 47.53963];
		var hasMarkers = false;
		if (typeof mapMarkers !== 'undefined' && Array.isArray(mapMarkers)) {
			hasMarkers = true;
			center = [mapMarkers[0].lng, mapMarkers[0].lat];
		}

		mesekertMap = new mapboxgl.Map({
			container: 'mesekertMap',
			style: 'mapbox://styles/seruka/cjxzr9fv314ov1dr29703nhtd',
			zoom: 15.5,
			center:center
		});
		
		mesekertMap.scrollZoom.disable();

		if (hasMarkers) {
			var min_lng = 100;
			var max_lng = 0;
			var min_lat = 100;
			var max_lat = 0;

			for (var i = 0; i < mapMarkers.length; i++) {
				var obj = mapMarkers[i];
				var el = document.createElement('div');
				
				el.className = 'map-marker';
				el.innerHTML = '<span class="marker-outer" title="'+obj.name+'">'+svg('marker-icon-'+(obj.id))+'</span>';
				new mapboxgl.Marker(el).setLngLat([obj.lng, obj.lat]).addTo(mesekertMap);
				var lat = parseFloat(obj.lat);
				var lng = parseFloat(obj.lng);
				
				if (lng < min_lng) {
					min_lng = lng;
				}

				if (lng > max_lng) {
					max_lng = lng;
				}

				if (lat < min_lat) {
					min_lat = lat;
				}

				if (lat > max_lat) {
					max_lat = lat;
				}
			}

			if (mapMarkers.length > 1) {
				mesekertMap.fitBounds([[min_lng, min_lat], [max_lng, max_lat]], {
					padding:60
				});
			}
		}
	}
}

function closeHelloBar(btn)
{
	var parent = btn.parent();
	if (parent && !parent.hasClass('closed')) {
		parent.addClass('closed');
		parent.slideToggle();

		$.ajax({
			url:ajax_controller+'closeHelloBar',
			dataType:'json',
			type:'post'
		});
	}
}

function isTouchDevice() {
	var touchDevice = false;
	if (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/)) {
		touchDevice = true;
	}
	return touchDevice;
}

function initHomeSlider(type) {
	var touchDevice = isTouchDevice();
	
	var options = {
		auto:true,
		pause:5000,
		infiniteLoop: true,
		touchEnabled: touchDevice,
		preventDefaultSwipeX: true,
		preventDefaultSwipeY: false,
		controls: false,
		mode: 'fade',
		speed: 1200

	};

	if (!type) {
		type = 'desktop';
	}
	
	var selector = type == 'mobile' ? '#mobileHomeSlider' : '#desktopHomeSlider';
	
	homeSlider = $(selector).bxSlider(options);
}